import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';

interface ExportProductType {
  id: string;
  text: string;
}

@Component({
  selector: 'np-filter-config',
  templateUrl: './filter-config.component.html',
  styleUrls: ['./filter-config.component.css']
})
export class FilterConfigComponent implements OnInit {
  @Input() exportParameter: ExportParameter;

  exportProductTypes: ExportProductType[] = [];
  selectedExportProductType: ExportProductType;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.exportProductTypes.push(
      {
        id: '',
        text: this.translate.instant('ExportAllProducts')
      },
      {
        id: 'onlyMasters',
        text: this.translate.instant('ExportOnlyMasterProducts')
      },
      {
        id: 'onlyVariants',
        text: this.translate.instant('ExportOnlyVariantProducts')
      },
      {
        id: 'noMasters',
        text: this.translate.instant('ExportAllButMaster')
      }
    );
    this.selectedExportProductType = this.exportProductTypes[0];
  }

  isExcel(): boolean {
    return (
      this.exportParameter.version == 'FIELDLIST' ||
      this.exportParameter.format == 'FIELDLIST' ||
      this.exportParameter.version == 'TEMPLATE' ||
      this.exportParameter.format == 'TEMPLATE'
    );
  }

  onExportProductsChanged(event) {
    const { id } = event.value;
    this.selectedExportProductType = this.exportProductTypes[0];
    if (id != '') this.exportParameter.exportMasterOfFilteredChilds = false;

    for (const item of this.exportProductTypes) {
      const active = item.id === id;
      if (active) {
        this.selectedExportProductType = item;
      }
      if (item.id !== '') {
        this.exportParameter[item.id] = active;
      }
    }
  }
}

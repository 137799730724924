<div class="d-flex flex-column" [ngStyle]="{ height: 'inherit' }">
  <dx-toolbar *ngIf="!onlyUpdateFilter">
    <dxi-item location="before">
      <div class="dx-field">
        <dx-select-box
          [dataSource]="exportFormats"
          [(value)]="config.format"
          fieldTemplate="field"
          label="{{ 'Export Format' | translate }}"
          (onValueChanged)="setExportVersions()"
        >
          <div *dxTemplate="let data of 'item'">
            {{ data | translate }}
          </div>
          <div *dxTemplate="let data of 'field'">
            <dx-text-box [readOnly]="true" value="{{ data | translate }}"></dx-text-box>
          </div>
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item location="before">
      <div class="dx-field">
        <dx-select-box
          *ngIf="exportVersions.length > 0"
          [dataSource]="exportVersions"
          fieldTemplate="field"
          [(value)]="config.version"
          label="{{ 'Export Version' | translate }}"
          (onValueChanged)="onVersionChange($event)"
        >
          <div *dxTemplate="let data of 'item'">
            {{ data | translate }}
          </div>
          <div *dxTemplate="let data of 'field'">
            <dx-text-box [readOnly]="true" value="{{ data | translate }}"></dx-text-box>
          </div>
        </dx-select-box>
        <dx-select-box
          *ngIf="exportToCustomers.length > 0"
          [dataSource]="exportToCustomers"
          fieldTemplate="field"
          displayExpr="name"
          valueExpr="id"
          [(value)]="config.version"
          label="{{ 'Export To Customer' | translate }}"
        >
          <div *dxTemplate="let data of 'field'">
            <dx-text-box [readOnly]="true" value="{{ data.name }}"></dx-text-box>
          </div>
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item location="before" width="100%">
      <div class="dx-field">
        <div
          id="useFeatureOrderSwitch"
          class="dx-field-label"
          style="width: 150px"
          *ngIf="
            (config.format == 'FIELDLIST' || config.version == 'FIELDLIST') && loginService.hasAddonEXCEL_ORDER_EXPORT
          "
        >
          {{ 'Features nach Order' | translate }}
        </div>
        <div class="dx-field-value">
          <dx-switch
            [(value)]="config.useFeatureOrder"
            *ngIf="
              (config.format == 'FIELDLIST' || config.version == 'FIELDLIST') && loginService.hasAddonEXCEL_ORDER_EXPORT
            "
          ></dx-switch>
          <dx-tooltip
            target="#useFeatureOrderSwitch"
            showEvent="mouseenter"
            hideEvent="mouseleave"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data = data; of: 'content'">
              {{
                'Hier können Sie einstellen, ob die Features nach Order oder nach Namen ausgegeben werden' | translate
              }}
            </div>
          </dx-tooltip>
        </div>
      </div>
    </dxi-item>

    <dxi-item location="after">
      <ng-content select="[header]"></ng-content>
    </dxi-item>
  </dx-toolbar>

  <dx-tab-panel repaintChangesOnly="true" [ngStyle]="{ 'overflow-y': 'hidden' }" class="flex-grow-1">
    <dxi-item *ngIf="!onlyUpdateFilter" title="{{ 'Konfiguration' | translate }}">
      <div *dxTemplate>
        <dx-scroll-view showScrollbar="always" height="inherit">
          <exportConfiguration
            [(exportParameter)]="config"
            [ngStyle]="{
              height: '100%',
              width: '100%',
              display: 'inline-block'
            }"
          ></exportConfiguration>
        </dx-scroll-view>
      </div>
    </dxi-item>

    <dxi-item title="{{ 'Filter auswählen' | translate }}">
      <div *dxTemplate>
        <dx-tab-panel repaintChangesOnly="true" [(selectedIndex)]="filterIndex" [ngStyle]="{ height: 'inherit' }">
          <dxi-item title="{{ 'Filter Einstellungen' | translate }}">
            <div *dxTemplate>
              <np-filter-config
                [(exportParameter)]="config"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></np-filter-config>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Produktstatus filtern' | translate }}">
            <div *dxTemplate>
              <productStateFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></productStateFilter>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Kategorien filtern' | translate }}">
            <div *dxTemplate>
              <categoryFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></categoryFilter>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Hersteller' | translate }}">
            <div *dxTemplate>
              <manufacturerFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></manufacturerFilter>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Lieferant' | translate }}">
            <div *dxTemplate>
              <supplierContentFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></supplierContentFilter>
            </div>
          </dxi-item>
          <dxi-item *ngIf="loginService.hasAddonUDX" title="{{ 'Udx filtern' | translate }}">
            <div *dxTemplate>
              <udxFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></udxFilter>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Datum' | translate }}">
            <div *dxTemplate>
              <dateFilter
                [(exportParameter)]="config"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></dateFilter>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Liste' | translate }}">
            <div *dxTemplate>
              <searchByListFilter
                [(exportParameter)]="config"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></searchByListFilter>
            </div>
          </dxi-item>
        </dx-tab-panel>
      </div>
    </dxi-item>

    <dxi-item title="{{ 'Inhalte auswählen' | translate }}">
      <div *dxTemplate>
        <dx-tab-panel [(selectedIndex)]="contentIndex" [ngStyle]="{ height: 'inherit' }">
          <dxi-item title="{{ 'Features auswählen' | translate }}">
            <div *dxTemplate>
              <featureContentFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></featureContentFilter>
            </div>
          </dxi-item>
          <dxi-item title="{{ 'Preislisten auswählen' | translate }}">
            <div *dxTemplate>
              <pricelistContentFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></pricelistContentFilter>
            </div>
          </dxi-item>
          <dxi-item *ngIf="loginService.hasAddonUDX" title="{{ 'Udx auswählen' | translate }}">
            <div *dxTemplate>
              <udxContentFilter
                [(exportParameter)]="config"
                [catalogId]="catalogId"
                [ngStyle]="{
                  height: '100%',
                  width: '100%',
                  display: 'inline-block'
                }"
              ></udxContentFilter>
            </div>
          </dxi-item>
        </dx-tab-panel>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="
        !onlyUpdateFilter &&
        loginService.hasAddonEXCEL_TEMPLATE &&
        (config.format == 'TEMPLATE' || config.version == 'TEMPLATE')
      "
      title="{{ 'Excel Template' | translate }}"
    >
      <div *dxTemplate>
        <dx-scroll-view showScrollbar="always" height="inherit">
          <template-editor
            class="w-100 h-100"
            [exportVersion]="config.version"
            [showSelector]="true"
            [exportMode]="true"
            [templateChange]="onTemplateChange.asObservable()"
          ></template-editor>
        </dx-scroll-view>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="!onlyUpdateFilter && (config.format == 'FIELDLIST' || config.version == 'FIELDLIST')"
      title="{{ 'Excel Fields' | translate }}"
    >
      <div *dxTemplate>
        <fieldList
          [fields]="catalogService.fields"
          [fieldGroups]="fieldGroups"
          [(fieldListContainer)]="config"
          [ngStyle]="{ height: 'inherit' }"
        ></fieldList>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="loginService.hasAddonWawiErp && config.format != 'TEMPLATE' && config.version != 'TEMPLATE'"
      title="{{ 'Mapping' | translate }}"
    >
      <div *dxTemplate>
        <wawiMappingChooser
          [fields]="wawiMappingFields"
          [fieldGroups]="fieldGroups"
          [(exportParameter)]="config"
          exportMode="true"
          [ngStyle]="{ height: 'inherit' }"
        ></wawiMappingChooser>
      </div>
    </dxi-item>

    <dxi-item *ngIf="!onlyUpdateFilter && config.version == 'CSV'" title="{{ 'CSV Einstellungen' | translate }}">
      <div *dxTemplate>
        <csvSettings [(exportParameter)]="config" [ngStyle]="{ height: 'inherit' }"></csvSettings>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="!onlyUpdateFilter && config.format == 'INTERFACE' && interfaceWithConfigs.includes(config.version)"
      title="{{ 'Schnittstellen Einstellung' | translate }}"
    >
      <div *dxTemplate>
        <np-interface-setting [(exportParameter)]="config" [ngStyle]="{ height: 'inherit' }"></np-interface-setting>
      </div>
    </dxi-item>
  </dx-tab-panel>

  <div
    [ngStyle]="{
      'margin-top': '10px',
      border: 'lightgray',
      'border-top': 'inset'
    }"
  >
    <dx-toolbar>
      <dxi-item [ngStyle]="{ 'margin-top': '10px' }">
        <div class="d-flex flex-column">
          <div class="{{ getClass() }}">{{ getConfigSummary() }}</div>
          <div class="d-flex flex-row" [ngStyle]="{ 'align-self': 'center', 'align-items': 'center' }">
            <ng-content></ng-content>
          </div>
        </div>
      </dxi-item>
    </dx-toolbar>
  </div>
</div>

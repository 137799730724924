<div class="d-flex flex-row" *ngIf="catalogName && catalogName != ''">
  <div class="flex-column p-1">
    <b>{{ 'Katalog' | translate }}:</b>
  </div>
  <div class="flex-column p-1">
    <a class="cataloglink" (click)="openCatalogById(job.catalogId)">{{ catalogName }}</a>
  </div>
</div>

<div class="d-flex flex-row" *ngIf="resultCatalogName != ''">
  <div class="flex-column p-1">
    <b>{{ 'Ergebnis Katalog' | translate }}:</b>
  </div>
  <div class="flex-column p-1">
    <a class="cataloglink" (click)="openCatalogById(job.resultCatalogId)">{{ resultCatalogName }}</a>
  </div>
</div>

<div class="d-flex flex-row" *ngIf="job.type != 'CatalogAction'">
  <div class="flex-column p-1">
    <b>{{ 'Dateiname' | translate }}:</b>
  </div>
  <div class="flex-column p-1">
    {{ job.name }}
  </div>
  <div class="flex-column p-1">
    <a
      *ngIf="job.fileNameBlobUri != undefined"
      [href]="job.fileNameBlobUri"
      class="dx-icon dx-icon-download"
      title="{{ 'Dateidownload' | translate }}"
    ></a>
  </div>
</div>

<div class="d-flex flex-row" *ngIf="job.started">
  <div class="flex-column p-1">
    <b>{{ 'Start' | translate }}:</b>
    {{ job.started | date : 'HH:mm:ss - dd.MMM' }}
  </div>
  <div class="flex-column p-1" *ngIf="!job.ended">
    <b>{{ 'Letztes Update' | translate }}:</b>
    {{ job.lastUpdate | date : 'HH:mm:ss - dd.MMM' }}
  </div>

  <div class="flex-column p-1" *ngIf="job.ended">
    <b>{{ 'Zeit' | translate }}:</b>
    {{ time(job.started, job.ended) }}
  </div>
</div>
<div class="d-flex flex-row">
  <div class="flex-column p-1">
    <b>{{ 'Datei' | translate }}:</b>
    <a href="{{ originialFileLink }}" title="{{ 'Dateidownload' | translate }}">{{ job.filename }}</a>

    <div *ngIf="hasExportParameter(this.job)">
      <dx-button (onClick)="togglePopup()">{{ 'Export Einstellungen' | translate }}</dx-button>
      <div *ngIf="this.exportParam != undefined">
        <dx-popup
          [(visible)]="isPopupVisible"
          [hideOnOutsideClick]="true"
          [showTitle]="true"
          class="d-flex flex-column"
          title="ExportParameter"
        >
          <exportParameter [(config)]="exportParam" [catalogId]="job.catalogId" [ngStyle]="{ height: '93%' }">
            <dx-button
              (click)="export($event)"
              stylingMode="text"
              type="default"
              text="{{ 'Exportieren' | translate }}"
            ></dx-button>
          </exportParameter>
        </dx-popup>
      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-row p-1" *ngIf="isValidatable(job)">
  <div class="flex-column">
    <dx-button type="normal" (click)="validateExport(job)">{{ 'Export validieren' | translate }}</dx-button>
  </div>
</div>

<div class="p-1" style="background-color: white">
  <div class="d-flex flex-row gap-1">
    <div class="align-self-center flex-column" style="width: 4rem">
      <b>{{ 'Zeit' | translate }}</b>
    </div>
    <div class="align-self-center flex-column"></div>
    <div class="flex-fill align-self-center flex-column flex-grow-1">
      <b>{{ 'Ereignis' | translate }}</b>
    </div>
  </div>

  <div class="d-flex flex-row gap-1" *ngFor="let event of events">
    <div class="align-self-center flex-column" style="width: 4rem">{{ event.created | date : 'HH:mm:ss' }}</div>
    <div class="align-self-center flex-column">
      <i
        class="dx-icon {{ event.eventType == 'Error' ? 'np-text-error ' : '' }}{{ getIconClass(event.eventType) }}"
      ></i>
    </div>
    <div
      *ngIf="event.eventType == 'HTML'; else notHTMLEvent"
      class="flex-fill align-self-center flex-column flex-grow-1"
      [innerHTML]="getEventMessage(event)"
    ></div>
    <ng-template #notHTMLEvent>
      <div class="flex-fill align-self-center flex-column flex-grow-1">
        {{ getEventMessage(event) }}
      </div>
    </ng-template>
  </div>
</div>

<br />
<div *ngIf="job.state == 3">
  <a href="{{ getHelpMaillink(job.id) }}">
    {{ 'Wenn Sie bei diesem Job Hilfe brauchen, dann behalten Sie bitte den Job' | translate }}
    <br />
    {{ 'und senden uns die JobID' | translate }}: {{ job.id }}
  </a>
</div>

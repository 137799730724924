export class ProductLogistic {

  constructor(
    public depthNumber?: number,
    public lengthNumber?: number,
    public weightNumber?: number,
    public widthNumber?: number,
    public countryOfOrigin?: string,
    public customsNumber?: string,
    public specialTreatmentClass?: string,
    public specialTreatmentClassType ?: string,
    public hazardousMaterialClassId?: string,
    public warrantyClassId?: string,
  ) {

  }
}
